<template>
	<div class="myPhotoAlert" v-if="show">
		<div class="myPhotoBg"></div>
		<div class="myPhotoDiv">
			<div class="myPhotoHead"></div>
			<div class="myPhotoCont">
				<div class="myPhotoMain">
					<div class="curPhotoDiv">
						<div class="curImg">
							<img :src="curPhotoUrl"/>							
						</div>
						<div class="curDes">当前头像</div>
					</div>
					<div class="curList">
						<div class="curListItem" v-for="(item,index) in photoList" :key="index" :class="item.id==curPhoto?'active':''" @click="selectPhoto(item)">
							<img :src="item.url"/>
							<span class="noselect"></span>
						</div>
					</div>
				</div>					
				<div class="photoFunBox">
					<span class="closeBtn" @click="closePhoto">关闭</span>
					<span class="comfirmBtn" @click="comfirmPhoto">确定</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import request from '@/service/request'
	import {Toast} from 'vant';
	
	export default defineComponent({
		data(){
			return{
				show:false,
				photoList:[],
				curPhoto:'',
				curPhotoUrl:''
			}
		},
		props:{
			config:{
				type:Object,
				default:()=>{
					return {}
				}
			}
		},
		watch:{
			config:{
				handler (newV, oldV) {					
					this.show=newV.isShow;
					console.log(newV,oldV,"wyth")
				},
				 deep:true
			}
		},
		created(){
			this.show=this.config.isShow;
			this.getPhotoList();
		},
		methods:{
			showSelectPhoto(){
				this.show=true;
			},
			closePhoto(){
				this.show=false;
			},
			comfirmPhoto(){
				
				request.apiAxios({
					method: 'post',
					url: request.SETAVATAR,
					data: {
						avatar:this.curPhoto
					}
				}).then((res) => {
					if (res.status) {
						Toast("修改成功！");
						this.show=false;
						this.$parent.setPhotoSelectData({id:this.curPhoto,url:this.curPhotoUrl});//调用父组件的方法						
					}else{
						Toast(res.message);
					}
				}).catch((err) => {
					console.log(err);
				})
				
			},
			//选择头像
			selectPhoto(item){
				this.curPhoto=item.id;
				this.curPhotoUrl=item.url;
			},
			
			getPhotoList(){
				request.apiAxios({
					method: 'post',
					url: request.AVATARLIST,
					data: {
						
					}
				}).then((res) => {
					if (res.status) {
						this.photoList=res.data.list;
						this.curPhoto=res.data.avatar_id;
						for(var i=0;i<this.photoList.length;i++){
							if(this.photoList[i].id==this.curPhoto){
								this.curPhotoUrl=this.photoList[i].url;
								break;
							}
						}						
					}
				}).catch((err) => {
					console.log(err);
				})
			}
		},
		setup(props,context){
			debugger;
		}
	});
</script>

<style lang="less" scoped="scoped">
	.myPhotoAlert{
		.myPhotoBg{
			position: fixed;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background:rgba(0,0,0,0.5);
		}
		
		.myPhotoDiv{
			position:fixed;
			height: 8.11rem;
			top: 50%;
			margin-top: -4.06rem;
			left: 50%;
			margin-left: -3.13rem;
			width: 6.26rem;
			border-radius: 0.14rem;
			
			.myPhotoHead{
				height: 0.94rem;
				background: linear-gradient(0deg, #95C5B5, #64A4A4);
				box-shadow: 0px 2px 19px 0px #42878E;
				width: 100%;
				border-radius:0.14rem 0.14rem 0 0;
			}
			.myPhotoMain{
				background: url(../assets/images/headselectbg.png) no-repeat;
				background-size: cover;
				margin-bottom: 0.25rem;
			}
			.myPhotoCont{
				background: linear-gradient(0deg, #EDEDD3, #DFEEE7);
				box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
				border-radius: 0 0 0.14rem 0.14rem;
				padding: 0.29rem 0.5rem 0.24rem;
				display: flex;
				    flex-direction: column;
				.curPhotoDiv{
					text-align: center;
					margin-bottom: 0.1rem;
				}
				.curImg{
					width: 1.3rem;
					height:1.3rem;
					border-radius: 50%;
					background: #EBEDDF;
					margin: 0.73rem auto 0.1rem;
					img{
						width:100%;
						border-radius: 50%;
					}
				}
				.curDes{
					color: #5D6262;
					font-size:0.25rem;
					
				}
				.curList{
					display: flex;
					flex-wrap:wrap;
					padding-left: 0.3rem;
					height: 3rem;
					overflow: auto;
					margin-bottom:0.3rem;
				}
				.curListItem{
					width: 0.95rem;
					height: 0.95rem;
					border:1px solid #EBEDDF;
					border-radius:50%;
					overflow: hidden;
					margin-right: 0.25rem;
					margin-bottom:0.25rem;
					position: relative;
					img{
						max-width: 100%;
						
					}
					
					.noselect{
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background:rgba(0,0,0,0.5);
						border-radius: 50%;
					}
					&.active {
						border: 1px solid #B4EDE2;
						.noselect{
							display: none;
						}
					}
				}
				.photoFunBox{
					display: flex;
					justify-content: space-around;
				}
				.closeBtn,.comfirmBtn{
					width: 1.6rem;
					height: 0.63rem;
					background: #73D1C7;
					box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
					border-radius: 0.32rem;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: #F8FAF5;
				}
				.comfirmBtn{
					color: #22656E;
					background: #B4EDE2;
				}
			}
		}
	}
	
</style>
