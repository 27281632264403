<template>
	<div class="myBox">
		<div class="main">
			<div class="backBox">
				<div class="back">
					<img class="backIcon" @click="goBack" src="@/assets/images/back_blue.png"/>
					<span class="backDes">编辑内容</span>
				</div>
				<van-button class="commBtn" v-preventReClick="1000" @click="saveEdit">保存</van-button>
			</div>
			<div class="infoBox">
				<div class="infoDiv">
					<div class="headIcon"><img class="headIcon" v-if="avatar" :src="avatar" /></div>
					<div class="selectIconBtn" @click="showPhotoSelect">头像选择</div>
				</div>
			</div>
			<div class="commBlock modifyBox">
				<div class="nickname">昵称 <span class="des">只有一次机会改名哦</span></div>
				<div class="inputDiv">
					<van-field
					    v-model="nickname"						    
						:readonly="nickname_lock==1"
					/>
				</div>
				<div class="tishi" v-if="isOkName">昵称内有违规字，请重新输入</div>
				<div class="nickname">ID<span class="des">(注册时生成，不可修改)</span></div>
				<div class="inputDiv">
					<van-field
					    v-model="ID"	
						readonly
						error-message=""
					/>
				</div>
			</div>			
		</div>
		<PHOTOSELECT ref="photoselect" v-if="showPhoneSelect" :config="selectPhongInitData"></PHOTOSELECT>
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import { Field, CellGroup,Toast,Button } from 'vant';
	import PHOTOSELECT from '../../components/photoSelect.vue';
	import request from '@/service/request';
	export default defineComponent({
		name: 'Home',
		components: {
			[Field.name]:Field,
			PHOTOSELECT,
			[Button.name]: Button
		},
		data(){
			return{
				nickname:'',
				ID:'',
				selectPhongInitData:{},
				isOkName:false,
				nickname_lock:0,
				showPhoneSelect:false,
				avatar:''
			}
		},
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		created(){
			this.getDetail();
		},
		methods: {
			getDetail() {
				request.apiAxios({
					method: 'post',
					url: request.INFO,
				}).then((res) => {
					if (res.status) {
						this.nickname=res.data.nickname;
						this.ID=res.data.uid;
						this.nickname_lock=res.data.nickname_lock;
						this.avatar=res.data.avatar;
					}
				}).catch((err) => {
					console.log(err);
				})
			},
			saveEdit(){				
				//汉字数字字母`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、#￥%……&*（）——+《》；：“”，
				var patrn = /^[\u4E00-\u9FA5A-Za-z0-9_&*%@#$￥：，、'!_+=-~()^{}?<>,.:""|——。《》]{1,16}$/g; 
				
				//console.log(patrn.test(this.nickname),this.nickname);
				  if (patrn.test(this.nickname)) {// 如果匹配就返回true
				    this.isOkName=false;
					request.apiAxios({
						method: 'post',
						url: request.EDITINFO,
						data:{
							nickname:this.nickname
						}
					}).then((res) => {
						if (res.status) {
							Toast.success("修改成功！");
							this.nickname_lock=1;
						}else{
							Toast(res.message);
						}
					}).catch((err) => {
						console.log(err);
					})
				  }else{
					   this.isOkName=true;					 
				  }				  
			},
			showPhotoSelect(){
				
				this.showPhoneSelect=true;
				this.selectPhongInitData={
					kk:'kk',
					isShow:true,
					list:[
						{kk:'cc'},
						{dd:'dd'}
					]
				};
				//this.$refs.photoselect.showSelectPhoto();//父组件调用子组件
			},
			setPhotoSelectData(item){
				this.avatar=item.url;
			}
			
		},
		setup(){
			function goBack(){				
				this.$router.replace('/my');
			}
			return{
				goBack
			}
		}
	});
</script>

<style lang="less" scoped="scoped">
	.main {
		padding: 0.2rem;
	}

	.infoBox {
		padding: 0.08rem;
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
		border-radius: 0.14rem;
		margin-bottom: 0.26rem;
	}
	.backBox{
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
		border-radius: 0.14rem;
		margin-bottom: 0.26rem;
		display: flex;
		align-items: center;
		padding:0.17rem 0.33rem 0.17rem 0.24rem;
		justify-content: space-between;
		color:#5D6262;
		.back{
			display: flex;
			align-items: center;
		}
		.backIcon{
			width: 0.4rem;
			margin-right: 0.4rem;
		}
	}

	.infoDiv {
		padding: 0.24rem;
		display: flex;
		align-items: center;
		.headIcon {
			width: 1.25rem;
			height: 1.25rem;
			flex: none;
			margin-right: 1.18rem;
		}
		.selectIconBtn{
			width: 2.41rem;
			height: 0.63rem;
			background: #73D1C7;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
			border-radius: 0.32rem;
			color:#F8FAF5;
			text-align: center;
			line-height: 0.63rem;
		}		
	}
	.modifyBox{
		padding:0.44rem 0.24rem;
		.inputDiv{
			margin-bottom:0.24rem;
			.van-cell{
				background:#E6E7DF;
				font-size:0.33rem;
				color:#5D6262;
			}
		}
		.nickname{
			color:#5D6262;
			font-size: 0.33rem;
			margin-bottom:0.23rem;
			.des{
				color:#20636C;
				font-size:0.25rem;
				padding:0 0.25rem;
			}
		}
		.tishi{
			color:#ED5859;
			font-size:0.25rem;
			margin-top:-0.15rem;
			margin-bottom:0.23rem;
		}
	}
	

</style>
<style lang="less">
	.myBox{
		.backBox{
			.commBtn{
				border:none;
				line-height:0.33rem;
				.van-button__text{
					font-size:0.26rem;
				}
			}
		}
	}
</style>
